import {
  loginUserAddToken,
  logoutUserFromStore,
  useAppDispatch,
  useAppSelector,
} from "@constituentvoice/cv-elements/web";
import {
  decodeJWT,
  fetchAuthSession,
  getCurrentUser,
  signOut,
} from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";

type AuthenticationState = "unauthenticated" | "configuring" | "authenticated";

const getEpoch = () => Math.floor(new Date().getTime() / 1000);

// This hook mimics the behavior from:
// https://github.com/aws-amplify/amplify-ui/blob/main/packages/react-core/src/Authenticator/context/AuthenticatorProvider.tsx
// But it actually works and doesn't conflict with the token generation
export const useAuthenticator = () => {
  const [authStatus, setAuthStatus] =
    useState<AuthenticationState>("configuring");
  const token = useAppSelector((state) => state.user.token);
  const dispatch = useAppDispatch();

  const unauthenticateAndLogOut = useCallback(() => {
    setAuthStatus("unauthenticated");
    // This will sign out user from amplify
    void signOut().catch(() => null);
    // This will clear the user token from cache
    // TODO: Double check this call, it can cause an infinite loop
    if (token) void dispatch(logoutUserFromStore());
  }, [dispatch, token]);

  useEffect(() => {
    getCurrentUser()
      .then(() => {
        // We wait for the token to be defined before finishing authentication
        // We store it in AsyncStorage and it may take a bit to return an actual value
        // So we will wait for it as long as Amplify says there's a user currently signed in
        if (token) {
          const {
            payload: { exp },
          } = decodeJWT(token);
          if (exp) {
            const now = getEpoch();
            const isTokenValid = (exp || 0) - now > 0;
            if (isTokenValid) {
              setAuthStatus("authenticated");
            } else {
              // Force refresh the auth session tokens because the existing token expired
              fetchAuthSession({ forceRefresh: true })
                .then(({ tokens }) => {
                  const newToken = tokens?.accessToken.toString();
                  if (newToken) {
                    // If we get a new token, it will be dispatched and this `useEffect` will execute again
                    void dispatch(loginUserAddToken(newToken));
                  } else {
                    unauthenticateAndLogOut();
                  }
                })
                .catch(() => {
                  unauthenticateAndLogOut();
                });
            }
          }
        }
      })
      .catch(() => {
        unauthenticateAndLogOut();
      });
  }, [token, unauthenticateAndLogOut, dispatch]);

  return { authStatus };
};
