import { RouteObject } from "react-router";

import { ReactRouterErrorBoundary } from "../components/ErrorBoundary/ReactRouterErrorBoundary.view";
import { ClientListLayout } from "../layouts/client-list-layout";
import { TopNavLayout } from "../layouts/TopNavLayout";
import { ValidateAuthLayout } from "../layouts/validate-auth-layout";
import { ActionCenterHomeView } from "../screens/ActionCenterHome/ActionCenterHome.view";
import { AttendeesScreenView } from "../screens/Attendees/AttendeesScreen.view";
import { BillDetailScreen } from "../screens/BillDetail/BillDetailScreen.view";
import { ChooseYourGroupView } from "../screens/ChooseYourGroup/choose-your-group.view";
import { DirectoryHomeScreenView } from "../screens/DirectoryHomeScreen/DirectoryHomeScreen.view";
import { EventBillsScreen } from "../screens/EventBills";
import { EventDocumentsScreenView } from "../screens/EventDocuments/EventDocumentsScreen.view";
import { EventSurveyScreenView } from "../screens/EventSurvey/EventSurveyScreen.view";
import { FAQScreenView } from "../screens/FAQ/FAQ.view";
import { HomeScreenView } from "../screens/Home/HomeScreen.view";
import { LegislatorBillsScreenView } from "../screens/LegislatorBillsScreen";
import { LegislatorsDetailsScreenView } from "../screens/Legislators/LegislatorDetails";
import { LegislatorsHomeScreenView } from "../screens/Legislators/LegislatorsHome/LegislatorsHomeScreen.view";
import { LobbyDaySelectionScreen } from "../screens/LobbyDay";
import { MeetingDetailScreenView } from "../screens/MeetingDetail";
import { MeetingDocumentsScreenView } from "../screens/MeetingDocuments/MeetingDocumentsScreen.view";
import { MeetingSurveyScreenView } from "../screens/MeetingSurvey";
import { MessagesHomeScreenView } from "../screens/MessagesHomeScreen/MessagesHomeScreen.view";
import { MoreHomeScreenView } from "../screens/MoreHome/MoreHomeScreen.view";
import { MyNotesScreenView } from "../screens/MyNotes/MyNotes.view";
import { PasswordlessLogin } from "../screens/PasswordlessLogin/PasswordlessLogin.view";
import { SettingsScreenView } from "../screens/Settings/SettingsScreen.view";
import { SupportScreenView } from "../screens/Support/Support.view";
import { SupportScreenPublicView } from "../screens/SupportPublic/SupportPublic.view";
import { TalkingPointsScreenView } from "../screens/TalkingPointsScreen/TalkingPointsScreen.view";
import { UserProfileScreenView } from "../screens/UserProfile/UserProfileScreen.view";
import { VoteDetailScreenView } from "../screens/VoteDetail/VoteDetail.view";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <TopNavLayout />,
    errorElement: <ReactRouterErrorBoundary />,
    children: [
      { index: true, element: <PasswordlessLogin />, path: "login" },
      {
        path: "support",
        element: <SupportScreenPublicView />,
      },
      {
        element: <ValidateAuthLayout />,
        children: [
          {
            index: true,
            element: <ChooseYourGroupView />,
          },
          {
            path: ":serviceCode",
            element: <ClientListLayout />,
            children: [
              { index: true, element: <HomeScreenView /> },
              {
                path: "/:serviceCode/meeting/:meetingId",
                element: <MeetingDetailScreenView />,
              },
              {
                path: "/:serviceCode/meeting/talking-points/:meetingId",
                element: <TalkingPointsScreenView />,
              },
              {
                path: "/:serviceCode/meeting/legislator-bills/:meetingId",
                element: <LegislatorBillsScreenView />,
              },
              {
                path: "/:serviceCode/meeting/:meetingId/survey",
                element: <MeetingSurveyScreenView />,
              },
              {
                path: "/:serviceCode/meeting/:meetingId/attendees",
                element: <AttendeesScreenView />,
              },
              {
                path: "/:serviceCode/legislators-home",
                element: <LegislatorsHomeScreenView />,
              },
              {
                path: "/:serviceCode/legislators-home/:legislatorId",
                element: <LegislatorsDetailsScreenView />,
              },
              {
                path: "/:serviceCode/more",
                element: <MoreHomeScreenView />,
              },
              {
                path: "/:serviceCode/lobby-day",
                element: <LobbyDaySelectionScreen />,
              },
              {
                path: "/:serviceCode/event-bills",
                element: <EventBillsScreen />,
              },
              {
                path: "/:serviceCode/event-bills/:billId",
                element: <BillDetailScreen />,
              },
              {
                path: "/:serviceCode/event-bills/:billId/votes/:voteId",
                element: <VoteDetailScreenView />,
              },
              {
                path: "/:serviceCode/meeting/:meetingId/documents",
                element: <MeetingDocumentsScreenView />,
              },
              {
                path: "/:serviceCode/event/:eventId/documents",
                element: <EventDocumentsScreenView />,
              },
              {
                path: "/:serviceCode/profile/:userId",
                element: <UserProfileScreenView />,
              },
              {
                path: "/:serviceCode/event/survey",
                element: <EventSurveyScreenView />,
              },
              {
                path: "/:serviceCode/directory",
                element: <DirectoryHomeScreenView />,
              },
              {
                path: "/:serviceCode/messages",
                element: <MessagesHomeScreenView />,
              },
              {
                path: "/:serviceCode/action-center",
                element: <ActionCenterHomeView />,
              },
              {
                path: "/:serviceCode/settings",
                element: <SettingsScreenView />,
              },
              {
                path: "/:serviceCode/user-support",
                element: <SupportScreenView />,
              },
              {
                path: "/:serviceCode/more/my-notes",
                element: <MyNotesScreenView />,
              },
              {
                path: "/:serviceCode/more/faq",
                element: <FAQScreenView />,
              },
            ],
          },
        ],
      },
    ],
  },
];
